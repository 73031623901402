import React from "react"
import { graphql } from "gatsby"

// Components
import ButtonTemplate from "../components/buttons/ButtonTemplate"
import DisplayPosts from "../components/DisplayPosts"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import PageSection from "../components/PageSection"
import SectionTitle from "../components/SectionTitle"
import SEO from "../components/seo"
import SquareLink from "../components/home/SquareLink"
import Syllabics from "../components/Syllabics"
import Poster from "../components/home/Poster"
import SevenGrandFatherTeachings from "../components/home/SevenGrandFatherTeachings"

const IndexPage = ({ data }) => {
  const {
    eldersSquare,
    calendarSquare,
    mapSquare,
    everyChildMattersFlyer,
    offReservePoster,
    reconciliationDay2022,
  } = data
  const blogPosts = data.allSanityBlogPost.edges
  const homePageData = data.allSanityHomePage.edges[0].node
  const {
    englishTitle,
    syllabicsTitle,
    aboutUsSection,
    heroImage,
  } = homePageData

  return (
    <Layout>
      <SEO title="Home" />
      <Hero
        englishTitle={englishTitle}
        syllabics={syllabicsTitle}
        heroImage={heroImage ? heroImage.asset : null}
        bgClassName="bg-bottom"
      ></Hero>
      <div className="grid grid-flow-col md:items-center justify-center  mb-5 md:mb-10 mt-4 md:mt-5 z-10 ">
        <SquareLink
          to="/pages/elders-proclamation/"
          fluid={eldersSquare.childImageSharp.fluid}
          // className="mt-2 lg:mt-0 "
        />
        <SquareLink
          to="/calendar"
          fluid={calendarSquare.childImageSharp.fluid}
          // className="mt-2 lg:mt-0 "
        />
        <SquareLink
          to="/map"
          fluid={mapSquare.childImageSharp.fluid}
          // className="mt-2 lg:mt-0 "
        />
      </div>

      <PageSection className="items-center">
        <SectionTitle>The Seven Grandfather Teachings</SectionTitle>
        <SevenGrandFatherTeachings />
      </PageSection>

      <PageSection>
        <SectionTitle>
          <Syllabics className="text-red-light">ᐊᐊᐧᓀᐣ ᓂᓇᐃᐧᐟ</Syllabics>
          <br />
          About Us
        </SectionTitle>
        <p className=" text-justify mx-4 md:mx-32 xl:mx-64 mb-8 text-lg font-medium">
          {aboutUsSection}
        </p>
        <ButtonTemplate
          to="/about"
          text="Learn More"
          className="m-4 mx-auto text-white"
        />
      </PageSection>

      <section className="grid mb-10" id="whatsnew">
        <SectionTitle className="">
          {" "}
          <Syllabics className="text-red-light">ᐅᐢᑭ ᐊᒋᒧᐃᐧᐣ</Syllabics>
          <br />
          What's New
        </SectionTitle>
        {/* Displays blog posts */}
        <DisplayPosts posts={blogPosts} />

        <ButtonTemplate
          to="/news"
          text="More News"
          className="text-white mx-auto mt-5"
          triangleFill="rgb(195,153,61)"
          bannerBg="rgb(225,184,77)"
        />
      </section>

      {/* <NewsletterSignUp>Get Notified of News & Events</NewsletterSignUp> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityBlogPost(
      filter: { inNewsFeed: { eq: true } }
      limit: 2
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          ...DisplayPostsFragment
        }
      }
    }

    allSanityHomePage {
      edges {
        node {
          englishTitle
          syllabicsTitle
          aboutUsSection
          heroImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }

    eldersSquare: file(relativePath: { eq: "icons/Elders.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    calendarSquare: file(relativePath: { eq: "icons/Calendar.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mapSquare: file(relativePath: { eq: "icons/Map.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
