import React from "react"
import ReactImageGallery from "react-image-gallery"

const SevenGrandFatherTeachings = () => {
  const images = [
    {
      title: "Wisdom",
      original:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_width-500/v1632157281/shibogama-7-teachings/Wisdom_zwtue6.png",
      thumbnail:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_Width 200/v1632157281/shibogama-7-teachings/Wisdom_zwtue6.png",
      originalAlt: "Seven Grandfather Teachings poster: Wisdom",
      thumbnailAlt: "Seven Grandfather Teachings poster: Wisdom",
    },
    {
      title: "Love",
      original:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_width-500/v1632157276/shibogama-7-teachings/Love_qkzl7f.png",
      thumbnail:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_Width 200/v1632157276/shibogama-7-teachings/Love_qkzl7f.png",
      originalAlt: "Seven Grandfather Teachings poster: Love",
      thumbnailAlt: "Seven Grandfather Teachings poster: Love",
    },
    {
      title: "Honesty",
      original:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_width-500/v1632157275/shibogama-7-teachings/Honesty_zyxvp6.png",
      thumbnail:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_Width 200/v1632157276/shibogama-7-teachings/Honesty_zyxvp6.png",
      originalAlt: "Seven Grandfather Teachings poster: Honesty",
      thumbnailAlt: "Seven Grandfather Teachings poster: Honesty",
    },
    {
      title: "Respect",
      original:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_width-500/v1632157275/shibogama-7-teachings/Respect_anxovg.png",
      thumbnail:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_Width 200/v1632157276/shibogama-7-teachings/Respect_anxovg.png",
      originalAlt: "Seven Grandfather Teachings poster: Respect",
      thumbnailAlt: "Seven Grandfather Teachings poster: Respect",
    },
    {
      title: "Bravery",
      original:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_width-500/v1632157274/shibogama-7-teachings/Bravery_rvpcnf.png",
      thumbnail:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_Width 200/v1632157276/shibogama-7-teachings/Bravery_rvpcnf.png",
      originalAlt: "Seven Grandfather Teachings poster: Bravery",
      thumbnailAlt: "Seven Grandfather Teachings poster: Bravery",
    },
    {
      title: "Truth",
      original:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_width-500/v1632157274/shibogama-7-teachings/Truth_wxc1bd.png",
      thumbnail:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_Width 200/v1632157276/shibogama-7-teachings/Truth_wxc1bd.png",
      originalAlt: "Seven Grandfather Teachings poster: Truth",
      thumbnailAlt: "Seven Grandfather Teachings poster: Truth",
    },
    {
      title: "Humility",
      original:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_width-500/v1632157271/shibogama-7-teachings/Humility_o0suy0.png",
      thumbnail:
        "https://res.cloudinary.com/borealis-web-development/image/upload/t_Width 200/v1632157276/shibogama-7-teachings/Humility_o0suy0.png",
      originalAlt: "Seven Grandfather Teachings poster: Humility",
      thumbnailAlt: "Seven Grandfather Teachings poster: Humility",
    },
  ]

  return (
    <div className=" w-64 md:w-84">
      <ReactImageGallery items={images} />
    </div>
  )
}

export default SevenGrandFatherTeachings
