import React from "react"

// A page section used on the Index page

const PageSection = ({ children, className = "" }) => {
  return (
    <section className={`flex flex-col mx-1 mb-12 ${className}`}>
      {children}
    </section>
  )
}

export default PageSection
